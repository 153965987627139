@font-face {
  font-family: 'Ethnocentric';
  src: url('./ethnocentric rg it.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ethnocentric';
  src: url('./ethnocentric rg.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
